import { Box, Heading, Text, Image } from '@chakra-ui/react';
import React from 'react';
import { useColors } from '../../../util/colors';
// import AboutImage from "../../../assets/images/about-us.png";
import { useActiveNav } from '../../../util/useActiveNav';

const AboutUs = () => {
  const aboutUsRef = useActiveNav('About Us');
  return (
    <Box
      ref={aboutUsRef}
      id='aboutContainer'
      w='100%'
      height={{ base: '100%', md: '100%', lg: '700px' }}
      pb={{ base: '50px' }}
      pt='56px'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      pl={{ md: '15px', lg: '30px' }}
      px={{ base: '7px' }}
      flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
      bgColor='#111210'
      color='#ffffff'
    >
      <Box width={{ base: '100%', sm: '100%', md: '60%', lg: '40%' }}>
        <Heading
          fontSize={{ base: '40px', md: '50px', lg: '60px' }}
          textAlign={{ base: 'center', md: 'initial', lg: 'initial' }}
        >
          Reach Your Career Projections at 10x Rate
        </Heading>
        <Text
          pt='20px'
          textAlign={{ base: 'center', md: 'initial', lg: 'initial' }}
          fontSize='20px'
        >
          At Taliot, we're all about helping you achieve your career goals at an
          accelerated pace. With our smart job search and personalized career
          guidance, you can reach your career projections at an astonishing 10x
          rate.
        </Text>
      </Box>
      <Box width={{ base: '100%', md: '90%', lg: '45%' }} pt={{ base: '20px' }}>
        <Image
          src={
            'https://res.cloudinary.com/taliot/image/upload/v1697199190/assets/landing_page/amkg2sedhsxxrsba5w8k.png'
          }
          alt='AboutImage'
          width={{ base: '100%', md: '100%', lg: '100%' }}
          height={{ base: '100%', md: '100%', lg: '462px' }}
          objectFit='cover'
          borderRadius='10px'
        />
      </Box>
    </Box>
  );
};

export default AboutUs;
