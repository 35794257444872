import api from "../axios";

const GetCategoriesApi = () => {
  return api.get(`/categories`);
};

const GetTaliosApi = () => {
  return api.get(`/talios`);
};

const CreateTaliosApi = (data) => {
  return api.post(`/talios/`, data, {headers: {"Content-Type": "multipart/form-data"}});
};

const DeleteTalioApi = (id) => {
  return api.delete(`/talios/${id}`);
};

export { GetCategoriesApi, CreateTaliosApi, GetTaliosApi, DeleteTalioApi };
