import { Flex, Text, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useColors } from "../../../util/colors";
import { BsCheckCircleFill } from "react-icons/bs";
import { useActiveNav } from "../../../util/useActiveNav";
import PaymentModal from "../components/PaymentModal";
import QrCodeModal from "../components/QrCodeModal";

const PremiumFeatures = [
  "All features of the Basic plan.",
  "Unlimited automated job applications.",
  "Advanced job matching and recommendations.",
  "Enhanced career advancement insights.",
  "Priority customer support.",
  `Access to all premium features, including personalized email templates, company exclusions,
  and more`,
];

const Pricing = ({ plan }) => {
  const pricingRef = useActiveNav("Pricing");
  
  return (
    <Flex
    width={{ base: "100%", md: "90%" }}
      mx="auto"
      mt="40px"
      gap="30px"
      id="pricingContainer"
      ref={pricingRef}
      justifyContent="center"
    >
      <Card
        type="Premium"
        price="$10"
        pricePerYear="$110"
        features={PremiumFeatures}
        paid={plan?.usedApplications < plan.maxApplications}
      />
    </Flex>
  );
};

const Card = ({
  price,
  pricePerYear,
  type,
  features,
  paid,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false)
  const [qrurl, setQrUrl] = useState(null)

  return (
    <Flex
      boxShadow="sm"
      p="25px"
      flexDirection="column"
      gap="30px"
      justifyContent="center"
      alignItems="center"
      width={{ base: "100%", md: "50%" }}
      bgColor={useColors.appSecondary}
      borderRadius="16px"
      color={useColors.appWhite}
    >
      <Flex flexDirection="column" alignItems="center" gap="10px">
        <Text fontSize="32px" fontWeight="700">
          {type}
        </Text>
        <Flex gap="5px">
          {" "}
          <Flex gap="10px" justifyContent="flex-end" alignItems="center">
            <Text fontSize="34px" fontWeight="600">
              {price}
            </Text>{" "}
            <Text fontSize="16px" fontWeight="400">
              per month
            </Text>
          </Flex>{" "}
          {pricePerYear && <Text fontSize="35px">/</Text>}
          {pricePerYear && (
            <Flex gap="10px" justifyContent="flex-end" alignItems="center">
              <Text fontSize="34px" fontWeight="600">
                {pricePerYear}
              </Text>{" "}
              <Text fontSize="16px" fontWeight="400">
                per year
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex flexDirection="column" gap="10px">
        {features.map((feature) => {
          return (
            <Flex width="100%" gap="15px">
              <BsCheckCircleFill color={useColors.appPrimary} width="10%" />
              <Text fontSize="16px" fontWeight="400" width="90%">
                {feature}
              </Text>
            </Flex>
          );
        })}
      </Flex>

      <Flex justifyContent="center" alignItems="center" width="100%">
        <Button
          height="50px"
          width="60%"
          borderRadius="15px"
          bgColor={useColors.appPrimary}
          border={useColors.appBlack}
          disabled={paid}
          cursor={paid ? "default" : "pointer"}
          opacity={paid ? 0.3 : 1}
          onClick={() => !paid ? setModalOpen(true) : null}
        >
          {paid ? "Selected" : "Select Plan"}
        </Button>
      </Flex>
      <PaymentModal modalOpen={modalOpen} setModalOpen={setModalOpen} setQrUrl={setQrUrl} setQrOpen={setQrOpen}/>
      <QrCodeModal  value={qrurl} modalOpen={qrOpen} setModalOpen={setQrOpen} />
    </Flex>
  );
};

export default Pricing;
