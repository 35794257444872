import api from "../axios";

const CreatePaymentApi = (data) => {
  return api.post(`/payment/create`, data);
};

const VerifyPaymentApi = () => {
  return api.post(`/payment/verifyPayment`);
};
export { CreatePaymentApi, VerifyPaymentApi };
