import { Box, Flex, Text } from "@chakra-ui/react";
import AboutUs from "./components/AboutUs";
import Education from "./components/Education";
import WebFooter from "../../Components/Footer";
import WebBanner from "../../Components/Banner";
import { useColors } from "../../util/colors";
import Pricing from "./components/Pricing";

const Welcome = () => {
  return (
    <Box w="100%" h="100%" overflowX="hidden">
      <WebBanner />
      <AboutUs />
      <Box
        pr={{ base: "10px", md: "32px" }}
        pl={{ base: "10px", md: "32px" }}
        pb="48px"
      >
        <Education />
        <Flex
          flexDirection="column"
          gap={{ base: "0px", md: "30px" }}
          alignItems="center"
          justifyContent="center"
          p={{ base: "20px", md: "40px" }}
        >
          <Text fontSize="50px" color={useColors.appBlack}>
            Pricing
          </Text>
          <Flex width={{ base: "100%", md: "80%" }} mx="auto">
            <Pricing />
          </Flex>
        </Flex>
      </Box>
      <WebFooter />
    </Box>
  );
};

export default Welcome;
