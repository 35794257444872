import {Flex, Text} from "@chakra-ui/react"

export const ContentBox = ({ title, count }) => {
    return (
      <Flex
        flexDirection="column"
        w={{base:"90%",md:"45%"}}
        h="250px"
        boxShadow="md"
        mx="auto"
        p="20px 50px"
        justifyContent="space-between"
      >
        <Flex alignItems="flex-start" justifyContent="flex-start">
          <Text fontSize={{base:"80px",md:"100px"}} fontWeight="600">
            {count}
          </Text>
        </Flex>
        <Flex alignItems="flex-end" justifyContent="flex-end">
          <Text fontSize={{base:"16px",md:"20px"}} fontWeight="600">
            {title}
          </Text>
        </Flex>
      </Flex>
    );
  };
  