import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Link,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { useColors } from '../../util/colors';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SignInApi } from '../../redux/axios/apis/auth';
import { dispatch } from '../../redux/store';
import { createResponse } from '../../redux/utils/UtilSlice';
import ErrorHandler from '../../redux/axios/Utils/ErrorHandler';
import { setToken } from '../../redux/features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const navigate = useNavigate();
  const [show, setValue] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const togglePassword = (event) => setValue(!show);

  const validationSchema = Yup.object({
    email: Yup.string().email('Is not a valid email').required('Required'),
    password: Yup.string().required('Required'),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await SignInApi(values);
      if (res) {
        setIsSubmitting(false);
        dispatch(setToken(res.data.data.accessToken));
        sessionStorage.setItem('token', res.data.data.accessToken);
        navigate('/dashboard');
      }
    } catch (error) {
      setIsSubmitting(false);
      dispatch(createResponse(ErrorHandler(error)));
    }
  };

  return (
    <Flex
      width='100%'
      justify='between'
      direction={{
        sm: 'column',
        base: 'row',
        md: 'column',
        lg: 'row',
        xl: 'row',
      }}
      height='100vh'
      color={useColors.appSecondary}
    >
      <Box
        display={{
          sm: 'none',
          base: 'none',
          md: 'none',
          lg: 'block',
          xl: 'block',
        }}
        width='50%'
        bgColor='#F9FAFB'
        padding='50px'
        height='100%'
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='120%'
          height='100%'
          margin='0'
        >
          <Image
            src='https://res.cloudinary.com/taliot/image/upload/v1697199103/assets/landing_page/qt07n83blux1guxxxgni.png'
            w='100%'
          />
        </Box>
      </Box>

      <Box px={{ base: "20px" }} width={{ lg: '30%', sm: '80%' }} margin='20px auto'>
        <Heading size='lg' color={useColors.appSecondary}>
          Welcome back!
        </Heading>

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, setValues, submitForm, handleChange, errors }) => (
            <Form className='w-full'>
              <Box marginTop='40px'>
                <FormControl marginY='16px'>
                  <FormLabel> Email address</FormLabel>
                  <Input
                    type='email'
                    name='email'
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {errors.email && <Text color='red'>{errors.email}</Text>}
                </FormControl>

                <FormControl marginY='30px'>
                  <Box display='flex' justify='between' width='100%'>
                    <FormLabel>Password</FormLabel>
                    <Link href='' marginLeft='auto'>
                      Forgot password?
                    </Link>
                  </Box>

                  <InputGroup size='md'>
                    <Input
                      pr='4.5rem'
                      type={show ? 'text' : 'password'}
                      placeholder='Enter password'
                      name='password'
                      onChange={(e) => handleChange(e)}
                    />

                    <InputRightElement width='4.5rem'>
                      <Button
                        h='1.75rem'
                        size='sm'
                        onClick={togglePassword}
                        bgColor={useColors.appGrey}
                      >
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <Text color='red'>{errors.password}</Text>
                  )}
                </FormControl>

                <Checkbox defaultChecked fontWeight='400'>
                  Remember Me
                </Checkbox>

                <Button
                  bgColor={useColors.appSecondary}
                  color='white'
                  width='100%'
                  fontWeight='bold'
                  marginY='20px'
                  type='submit'
                >
                  {isSubmiting ? 'Signing you in...' : 'Login'}
                </Button>
                <Text mt='10px' mb='20px' textAlign='center'>
                  Don't have an account?{' '}
                  <Link
                    href='/signup'
                    marginLeft='16px'
                    color={useColors.appBlack}
                    fontWeight='bold'
                  >
                    Get started
                  </Link>
                </Text>

                <Text
                  textAlign='center'
                  marginLeft='16px'
                  marginTop='20px'
                  fontWeight='bold'
                >
                  By logging in, you accept our{' '}
                  <Link href='/legal/privacy-policy' color={useColors.appBlack}>
                    privacy policy{' '}
                  </Link>
                  and{' '}
                  <Link href='/legal/terms-of-use' color={useColors.appBlack}>
                    terms of use
                  </Link>
                </Text>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default SignIn;
