import React from "react";

import Select from "react-select";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export const MultiSelect = ({options, onChange}) => (
  <Select
    isMulti
    closeMenuOnSelect={false}
    name="stack"
    options={options}
    components={animatedComponents}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e) => onChange(e)}
  />
);
