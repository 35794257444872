import { InputGroup } from "@chakra-ui/react";
import { useRef } from "react";

export default function FileUpload({accept, multiple, children, handleChange}) {
  const inputRef = useRef(null);

  const handleClick = () => inputRef.current?.click();
  return (
    <InputGroup onClick={handleClick} w="100%">
      <input
      
        type={"file"}
        multiple={multiple || false}
        hidden
        accept={accept}
        onChange={handleChange}
        ref={(e) => {
          inputRef.current = e;
        }}
      />
      <>{children}</>
    </InputGroup>
  );
}
