import { Flex, Text, Button } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useColors } from "../../util/colors";
import noTaliosImage from "../Talios/assets/undraw_no_data_re_kwbl.svg";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <Flex flexDirection="column" w="100%">
      <Flex justifyContent="space-between">
        <Text fontSize="23px" fontWeight={600} color={useColors.appSecondary}>
          Hello {user.firstName} 😊, here are your points
        </Text>
      </Flex>

      <Flex justifyContent="center" alignItems="center" gap="20px" flexDirection="column" h="70vh">
        <Text fontSize="135px" fontWeight={600} color={useColors.appSecondary}>0</Text>
        <Button
          bgColor={useColors.appSecondary}
          opacity={0.5}
          cursor="default"
          color={useColors.appWhite}
          disabled={true}
        >
          Redeem Points
        </Button>
      </Flex>
    </Flex>
  );
};

export default Wallet;
