import { Flex, Text, Button, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useColors } from "../../util/colors";
import CreateTalioModal from "./components/CreateTalioModal";
import { GetCategoriesApi, GetTaliosApi } from "../../redux/axios/apis/talios";
import { dispatch } from "../../redux/store";
import {
  setCategories,
  setTalios,
} from "../../redux/features/tailos/talioSlice";
import { createResponse } from "../../redux/utils/UtilSlice";
import ErrorHandler from "../../redux/axios/Utils/ErrorHandler";
import SkeletonLoader from "../../Components/Loaders/SkeletonLoader";
import { ContentBox } from "./components/contentBox";
import noTaliosImage from "../Talios/assets/undraw_no_data_re_kwbl.svg";
import { GetTalios } from "../../redux/features/tailos/service";

const Talios = () => {
  const { user } = useSelector((state) => state.user);
  const { talios } = useSelector((state) => state.talio);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (talios) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [talios]);
  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await GetCategoriesApi();
        if (res) {
          dispatch(setCategories(res.data.data));
        }
      } catch (error) {
        dispatch(createResponse(ErrorHandler(error)));
      }
    };

    getCategories();
    dispatch(GetTalios());
  }, []);

  return (
    <Flex flexDirection="column" w="100%">
      <Flex justifyContent="space-between" gap='10px'>
        <Text fontSize={{base:"19px",md:"23px"}} fontWeight={600} color={useColors.appSecondary}>
          Hello {user.firstName} 😊, let's create some talios
        </Text>
        <Button
          bgColor={useColors.appSecondary}
          color={useColors.appWhite}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Create Talio
        </Button>
      </Flex>

      <Flex justifyContent="center" alignItems="center">
        {isLoading ? (
          <SkeletonLoader />
        ) : talios.length > 0 ? (
          <Flex flexWrap="wrap" mt="30px" gap="5">
            {talios.map((talio) => (
              <ContentBox talio={talio}/>
            ))}
          </Flex>
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
            direction="column"
            mt="80px"
            style={{ width: "30%", height: "25%" }}
          >
            {talios.length === 0 && (
              <>
                <Image
                  src={noTaliosImage}
                  alt="No Talios"
                  // style={{ width: "50%", height: "100%" }}
                />
                <Text fontSize="15px" fontWeight="600" mt="20px">
                  You don't have any talios yet.
                </Text>
              </>
            )}
          </Flex>
        )}
      </Flex>
      <CreateTalioModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </Flex>
  );
};

export default Talios;
