import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  Text,
  FormControl,
  FormLabel,
  ModalFooter,
  Select,
  Box,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import FileUpload from "../../../Components/Form/FileUpload";
import { BsUpload } from "react-icons/bs";
import { useSelector } from "react-redux";
import { MultiSelect } from "../../../Components/Form/MultiSelect";
import { CreateTaliosApi } from "../../../redux/axios/apis/talios";
import { dispatch } from "../../../redux/store";
import { createResponse, setLoading } from "../../../redux/utils/UtilSlice";
import ErrorHandler from "../../../redux/axios/Utils/ErrorHandler";
import { formatString } from "../helpers";
import { GetTalios } from "../../../redux/features/tailos/service";

const CreateTalioModal = ({ setModalOpen, modalOpen }) => {
  const { categories } = useSelector((state) => state.talio);
  const { onClose } = useDisclosure();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [stack, setStack] = useState([]);
  const [skillLevel, setSkillLevel] = useState("");
  const [file, setFile] = useState(null);
  const [Categories, setCatgories] = useState([]);
  const [SubCategories, setSubCatgories] = useState([]);
  const [stacks, setStacks] = useState([]);
  const [coverLetterValue, setcoverLetterValue] = useState("");

  const handleSubmit = async () => {
    const stackData = stack.map((x) => x.value);
    const formBody = new FormData();
    formBody.append("category", category);
    formBody.append("subCategory", subCategory);
    formBody.append("stack", stackData);
    formBody.append("skillLevel", skillLevel);
    formBody.append("file", file);

    const coverLetterBase64 = btoa(coverLetterValue);
    formBody.append("coverLetter", coverLetterBase64);

    setModalOpen(false);
    dispatch(setLoading(true));
    try {
      const res = await CreateTaliosApi(formBody);

      if (res) {
        dispatch(GetTalios());
        dispatch(setLoading(false));
        setFile(null);
        dispatch(
          createResponse({
            title: "Success",
            type: "success",
            message: "Talio created successfully",
          })
        );
      }
    } catch (error) {
      setModalOpen(false);
      dispatch(setLoading(false));
      dispatch(createResponse(ErrorHandler(error)));
    }
  };

  useEffect(() => {
    if (categories) {
      const result = categories.map((x) => x.name);
      setCatgories(result);
    }
  }, [categories]);

  useEffect(() => {
    if (category !== "") {
      const result = categories.find((x) => x.name);
      setSubCatgories(result.subcategories);
    }
  }, [category, categories]);

  useEffect(() => {
    if (subCategory !== "") {
      const sub = SubCategories.find((x) => x.name === subCategory);
      const result = sub.stacks.map((x) => {
        return {
          label: x.charAt(0).toUpperCase() + x.slice(1),
          value: x,
        };
      });
      setStacks(result);
    }
  }, [subCategory]);

  return (
    <Modal isOpen={modalOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create your talio</ModalHeader>
        <ModalCloseButton onClick={() => setModalOpen(false)} />
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          <ModalBody pb={6}>
            <Flex flexDirection="column" gap="15px">
              <FormControl>
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select category"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {Categories.map((x) => (
                    <option value={x}>
                      {x.charAt(0).toUpperCase() + x.slice(1)}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Sub-Category</FormLabel>
                <Select
                  placeholder="Select sub category"
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  {SubCategories.map((x) => (
                    <option value={x.name}>{formatString(x.name)}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Stack</FormLabel>
                <MultiSelect
                  options={stacks}
                  onChange={(e) => {
                    setStack([...e]);
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Skill level</FormLabel>
                <Select
                  placeholder="Select level"
                  onChange={(e) => setSkillLevel(e.target.value)}
                >
                  <option value="junior">Junior</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="senior">Senior</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Upload your resume</FormLabel>
                <FileUpload
                  accept=".pdf"
                  multiple={false}
                  handleChange={(e) => setFile(e.target.files[0])}
                >
                  <Box
                    w={{ base: "100%", md: "420px" }}
                    display="flex"
                    flexWrap="wrap"
                    bg="#E8F7F5"
                    borderRadius="0.625rem"
                    cursor="pointer"
                  >
                    <Box ml="1rem" py="1.25rem">
                      <BsUpload />
                    </Box>
                    <Box>
                      <Text
                        ml="0.5rem"
                        py="1.25rem"
                        fontSize="0.75rem"
                        fontWeight="400"
                      >
                        {file
                          ? file.name
                          : "Browse files or drag and drop ( PDF,JPG or PNG)"}
                      </Text>
                    </Box>
                  </Box>
                </FileUpload>
              </FormControl>
              <FormControl>
                <FormLabel>Cover Letter</FormLabel>
                <Textarea
                  placeholder="Add cover letter..."
                  size="md"
                  rows={5}
                  resize="vertical"
                  onChange={(e) => setcoverLetterValue(e.target.value)}
                />
              </FormControl>
            </Flex>
          </ModalBody>
        </div>

        {/* @TODO: on creating a talio, no success or error response is displayed, the model should also closed and the page refreshed when a talio is created */}
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => handleSubmit()}>
            Create
          </Button>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateTalioModal;
