import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import Logo from "../../assets/svgs/HorizontalLogo.svg";
import { Link, useLocation } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { AiFillAppstore, AiFillHome } from "react-icons/ai";
import { FaSpaceShuttle } from "react-icons/fa";
import { BiSolidWalletAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useColors } from "../../util/colors";
import LogoOnly from "../../assets/svgs/Icon Logo Green.svg";
import { dispatch } from "../../redux/store";
import { setSideBarState } from "../../redux/utils/UtilSlice";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);
  const { sidebar } = useSelector((state) => state.util);

  return (
    <Box id="collapse-sidebar" className="side-panel">
      <Box position="relative" width="100%" height="100%">
        <Flex
          h="70px"
          p={!sidebar ? "39px 5px" : "39px"}
          w="100%"
          alignItems="center"
          justifyContent="center"
        >
          {sidebar ? (
            <img
              src={Logo}
              alt="logo"
              style={{ height: "inherit", width: "inherit" }}
            />
          ) : (
            <img src={LogoOnly} alt="logo" />
          )}
        </Flex>

        <Flex
          flexDirection="column"
          mt={0}
          py={1}
          px={1}
          width="100%"
          borderTop="1px solid rgba(0, 0, 0, 30%)"
          gap="15px"
        >
          <Link to={`/dashboard/`}>
            {sidebar ? (
              <Box
                bg={
                  pathname === "/dashboard/" || pathname === "/dashboard"
                    ? useColors.appPrimary
                    : "inherit"
                }
                display="flex"
                gap="10px"
                cursor="pointer"
                fontWeight={600}
                borderRadius="10px"
                my={1.5}
                p="10px 30px"
                _hover={{
                  bg: useColors.appPrimary,
                  transform: "scale(1.05)",
                }}
              >
                <AiFillHome
                  style={{ fontSize: "20px", margin: "auto 5px" }}
                  color={useColors.appSecondary}
                />
                <span className="nav-item">Home</span>
              </Box>
            ) : (
              <AiFillHome style={{ fontSize: "35px", margin: "15px auto" }}  onClick={() => dispatch(setSideBarState(true))}/>
            )}
          </Link>
          <Link to={`talios`}>
            {sidebar ? (
              <Box
                bg={
                  pathname.includes("talios") ? useColors.appPrimary : "inherit"
                }
                display="flex"
                gap="10px"
                cursor="pointer"
                fontWeight={600}
                borderRadius="10px"
                my={1.5}
                p="10px 30px"
                _hover={{
                  bg: useColors.appPrimary,
                  transform: "scale(1.05)",
                }}
              >
                <FaSpaceShuttle
                  style={{ fontSize: "20px", margin: "auto 5px" }}
                  color={useColors.appSecondary}
                />
                <span className="nav-item">My Talios</span>
              </Box>
            ) : (
              <FaSpaceShuttle
                style={{ fontSize: "35px", margin: "15px auto" }}
                color={useColors.appSecondary}
                onClick={() => dispatch(setSideBarState(true))}
              />
            )}
          </Link>

          <Link to={`applications`}>
            {sidebar ? (
              <Box
                bg={
                  pathname.includes("applications")
                    ? useColors.appPrimary
                    : "inherit"
                }
                display="flex"
                gap="10px"
                cursor="pointer"
                fontWeight={600}
                borderRadius="10px"
                my={1.5}
                p="10px 30px"
                _hover={{
                  bg: useColors.appPrimary,
                  transform: "scale(1.05)",
                }}
              >
                <AiFillAppstore
                  style={{ fontSize: "20px", margin: "auto 5px" }}
                  color={useColors.appSecondary}
                />
                <span className="nav-item">My Applications</span>
              </Box>
            ) : (
              <AiFillAppstore
                style={{ fontSize: "35px", margin: "15px auto" }}
                color={useColors.appSecondary}
                onClick={() => dispatch(setSideBarState(true))}
              />
            )}
          </Link>

          <Link to={`wallet`}>
            {sidebar ? (
              <Box
                bg={
                  pathname.includes("wallet") ? useColors.appPrimary : "inherit"
                }
                display="flex"
                gap="10px"
                cursor="pointer"
                fontWeight={600}
                borderRadius="10px"
                my={1.5}
                p="10px 30px"
                _hover={{
                  bg: useColors.appPrimary,
                  transform: "scale(1.05)",
                }}
              >
                <BiSolidWalletAlt
                  style={{ fontSize: "20px", margin: "auto 5px" }}
                  color={useColors.appSecondary}
                  onClick={() => dispatch(setSideBarState(true))}
                />
                <span className="nav-item">Wallet</span>
              </Box>
            ) : (
              <BiSolidWalletAlt
                style={{ fontSize: "35px", margin: "15px auto" }}
                color={useColors.appSecondary}
              />
            )}
          </Link>

          <Link to={`settings`}>
            {sidebar ? (
              <Box
                bg={
                  pathname.includes("settings")
                    ? useColors.appPrimary
                    : "inherit"
                }
                display="flex"
                gap="10px"
                cursor="pointer"
                fontWeight={600}
                borderRadius="10px"
                my={1.5}
                p="10px 30px"
                _hover={{
                  bg: useColors.appPrimary,
                  transform: "scale(1.05)",
                }}
              >
                <IoMdSettings
                  style={{ fontSize: "20px", margin: "auto 5px" }}
                  color={useColors.appSecondary}
                />
                <span className="nav-item">Settings</span>
              </Box>
            ) : (
              <IoMdSettings
                style={{ fontSize: "35px", margin: "15px auto" }}
                color={useColors.appSecondary}
                onClick={() => dispatch(setSideBarState(true))}
              />
            )}
          </Link>
        </Flex>
        <Box
          width="100%"
          borderTop="1px solid rgba(0, 0, 0, 30%)"
          position="absolute"
          bottom={"5px"}
          left={0}
        >
          {sidebar ? (
            <Flex alignItems="center" justifyContent="center" gap="0px">
              <Avatar src={user.profileImage} name={user.firstName} size="lg" />
              <Text fontSize="26px" fontWeight="600" lineHeight="29px">
                {user.firstName}
              </Text>
            </Flex>
          ) : (
            <Flex alignItems="center" justifyContent="center" gap="0px" p="2">
              {" "}
              <Avatar
                name={`${user.firstName} ${user.lastName}`}
                size="md"
                bgColor={useColors.appSecondary}
                color={useColors.appPrimary}
              />
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
