import ErrorHandler from "../../axios/Utils/ErrorHandler";
import { GetTaliosApi } from "../../axios/apis/talios";
import { dispatch } from "../../store";
import { createResponse, setLoading } from "../../utils/UtilSlice";
import { setTalios } from "./talioSlice";

export const GetTalios = () => async () =>  {
  dispatch(setLoading(true));
  try {
    const res = await GetTaliosApi();
    dispatch(setTalios(res.data.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(createResponse(ErrorHandler(error)));
  }
};

