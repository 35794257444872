import { Box, Heading, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import TagImage from '../../../assets/images/tagImg.png';
// import CardImage1 from '../../../assets/images/img5.jpg';
// import CardImage2 from '../../../assets/images/img2.png';
// import CardImage3 from '../../../assets/images/img3.jpg';
// import CardImage4 from '../../../assets/images/img4.jpg';
import { useColors } from '../../../util/colors';
import { useActiveNav } from '../../../util/useActiveNav';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Navigation, Autoplay } from 'swiper';
import '../../../assets/css/Swiper.css';
const Education = () => {
  const educationRef = useActiveNav('Education');

  return (
    <Box ref={educationRef} id='educationContainer' w='100%' h='100%' mt='30px'>
      <Flex justifyContent='center' alignItems='center' pt='30px' w='100%'>
        <Heading
          fontSize={{ base: '35px', md: '60px', lg: '80px' }}
          color='#111210'
        >
          Did <span style={{ color: useColors.appYellow }}>you </span> know
        </Heading>
        <Image
          borderRadius='md'
          src={TagImage}
          alt='TagImage'
          fontSize={{ base: '5%', md: '50%', lg: '100%' }}
        />
      </Flex>

      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        navigation={true}
        autoplay={true}
        modules={[Navigation, Autoplay]}
        className='mySwiper'
      >
        <SwiperSlide className='Card-Swiper'>
          <Box>
            <Text fontSize={{ base: '15px', md: '18px' }} textAlign='initial'>
              Did you know that Taliot reduces the time spent on job searches by
              up to 70%? Spend less time scrolling through listings and more
              time pursuing your dream career.
            </Text>
            <Image
              borderRadius='md'
              src={
                'https://res.cloudinary.com/taliot/image/upload/v1697199178/assets/landing_page/hsm2ncuice5dv1lrajq8.jpg'
              }
              alt='CardImage'
              w='100%'
              h='400px'
              mt='20px'
              objectFit='cover'
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide className='Card-Swiper'>
          <Box>
            <Text fontSize={{ base: '15px', md: '18px' }} textAlign='initial'>
              Did you know that Taliot's automated application feature lets you
              apply to multiple jobs in seconds with just one click? Say goodbye
              to repetitive form-filling and hello to efficiency.
            </Text>
            <Image
              borderRadius='md'
              src={
                'https://res.cloudinary.com/taliot/image/upload/v1697199156/assets/landing_page/h4qbfwcyxwaximne7jtz.png'
              }
              alt='CardImage'
              w='100%'
              h='400px'
              objectFit='contain'
              mt='20px'
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide className='Card-Swiper'>
          <Box>
            <Text fontSize={{ base: '15px', md: '18px' }} textAlign='initial'>
              Did you know that Taliot provides you with personalized career
              insights tailored to your skills and aspirations? Unlock a world
              of opportunities and stay ahead in your field.
            </Text>
            <Image
              borderRadius='md'
              src={
                'https://res.cloudinary.com/taliot/image/upload/v1697199466/assets/landing_page/tqc3kvipditoyxdoqnpe.jpg'
              }
              alt='CardImage'
              w='100%'
              h='400px'
              objectFit='cover'
              mt='20px'
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide className='Card-Swiper'>
          <Box>
            <Text fontSize={{ base: '15px', md: '18px' }} textAlign='initial'>
              Did you know that Taliot has already transformed the careers of
              thousands of users just like you? Join our community and be part
              of a success story.
            </Text>
            <Image
              borderRadius='md'
              src={
                'https://res.cloudinary.com/taliot/image/upload/v1697199155/assets/landing_page/ysljg0lyi5dyxvg8ztul.jpg'
              }
              alt='CardImage'
              w='100%'
              h='400px'
              objectFit='cover'
              mt='20px'
            />
          </Box>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default Education;
