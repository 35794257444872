import api from "../axios";

const CreateSecurityApi = (data) => {
  return api.post(`/security/create/`, data);
};

const UpdateSecurityApi = (data) => {
  return api.patch(`/security/update/`, data);
};

export { CreateSecurityApi, UpdateSecurityApi};
