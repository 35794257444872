import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  Text,
  Flex,
  Image,
} from "@chakra-ui/react";
import { dispatch } from "../../../redux/store";
import { createResponse, setLoading } from "../../../redux/utils/UtilSlice";
import ErrorHandler from "../../../redux/axios/Utils/ErrorHandler";
import SolanaLogo from "../../../assets/images/SolanaLogo.jpeg";
import { CreatePaymentApi } from "../../../redux/axios/apis/payment";

const PaymentModal = ({ setModalOpen, modalOpen, setQrUrl, setQrOpen }) => {
  const { onClose } = useDisclosure();
  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      setModalOpen(false)
      const res = await CreatePaymentApi();
      if (res.data) {
        setModalOpen(false);
        setQrUrl(res.data.data.url);
        setQrOpen(true);
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(createResponse(ErrorHandler(false)));
    }
  };

  return (
    <Modal isOpen={modalOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="25px">Upgrade you plan</ModalHeader>
        <ModalCloseButton onClick={() => setModalOpen(false)} />
        <ModalBody pb={6} borderTop="1px solid #00">
          <Flex
            flexDirection="column"
            gap="30px"
            padding="20px 0px"
            width="100%"
          >
            <Flex
              h="70px"
              width="70%"
              justifyContent="center"
              alignItems="center"
              gap="15px"
              borderRadius="20px"
              onClick={() => handleSubmit()}
              boxShadow="md"
              border="1px solid #FEFEFE"
            >
              <Image src={SolanaLogo} h="30px" w="10%" />
              <Text fontSize="25px" fontWeight="600" width="70%">
                Pay with Solana
              </Text>
            </Flex>

          
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
