import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: null,
  talios: null,
  currentTalio: null
};

const TalioSlice = createSlice({
  name: "talio",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setTalios: (state, action) => {
      state.talios = action.payload;
    },
    setCurrentTalio: (state, action) => {
      state.currentTalio = action.payload;
    },
  },
});

export const {setCategories, setTalios, setCurrentTalio} = TalioSlice.actions;

export default TalioSlice.reducer;
