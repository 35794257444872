import axios from 'axios';
import { setToken } from '../features/auth/authSlice';
import { dispatch } from '../store';

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': true,
  'Content-Type': 'application/json',
};

axios.defaults.baseURL = 'https://taliot.oa.r.appspot.com/v1/';
//http://localhost:1337/v1/
// https://taliot.oa.r.appspot.com/v1/

const axiosInstance = axios.create({
  headers,
  timeout: 60000,
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    dispatch(setToken(token));
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance;
