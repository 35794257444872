import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  Text,
  Flex,
  Image,
} from "@chakra-ui/react";
import QRCode from "react-qr-code";
import { useColors } from "../../../util/colors";
import { dispatch } from "../../../redux/store";
import { createResponse, setLoading } from "../../../redux/utils/UtilSlice";
import { VerifyPaymentApi } from "../../../redux/axios/apis/payment";
import ErrorHandler from "../../../redux/axios/Utils/ErrorHandler";
import { GetUserPlanApi } from "../../../redux/axios/apis/plan";

const QrCodeModal = ({ setModalOpen, modalOpen, value }) => {
  const { onClose } = useDisclosure();

  const ConfirmPayment = async () => {
      try {
        dispatch(setLoading(true));
        setModalOpen(false);
        const res = await VerifyPaymentApi();
        if (res.data) {
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(createResponse(ErrorHandler(false)));
      }
    
  }
  return (
    <Modal isOpen={modalOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="25px">Scan this code to Pay</ModalHeader>
        <ModalCloseButton onClick={() => setModalOpen(false)} />
        <ModalBody pb={6} borderTop="1px solid #00">
          <Flex
            flexDirection="column"
            gap="30px"
            padding="20px 0px"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <QRCode value={value} />

            <Flex
              height="50px"
              width="60%"
              borderRadius="15px"
              bgColor={useColors.appSecondary}
              color={useColors.appWhite}
              cursor={"pointer"}
              boxShadow="md"
              alignItems="center"
              justifyContent="center"
              fontSize="20px"
              onClick={() => ConfirmPayment()}
            >
              Confirm Payment
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QrCodeModal;
