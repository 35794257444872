import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Select,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UpdateProfileApi } from "../../../redux/axios/apis/profile";
import { dispatch } from "../../../redux/store";
import { setUser } from "../../../redux/features/user/userSlice";

const ProfileForms = () => {
  const toast = useToast();
  const { user } = useSelector((state) => state.user);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [userName, setUserName] = useState(user.username);
  const [edited, setEdited] = useState(true);

  const formBody = {
    firstName,
    lastName,
    userName,
  };

  const FormHandler = async () => {
    try {
      const res = await UpdateProfileApi(formBody);
      if (res) {
        toast({
          title: res.data.message,
          status: "success",
          duration: 5000,
        });
        dispatch(setUser(res.data.data));
        
        setEdited(true);
      }
    } catch (error) {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 5000,
      });
    }
  };

  return (
    <Box w="100%" h="auto" bg="white" p={{ base: 5, md: 7 }} borderRadius={5}>
      <Flex justifyContent="space-between" flexWrap="wrap-reverse">
        <Box w="630px">
          <FormControl>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Box>
                <Box w={{ base: "250px", md: "300px" }} mb="5">
                  <FormLabel fontSize={14}>First name</FormLabel>
                  <Input
                    type="text"
                    w="100%"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    bg="#EEEEEE"
                    border="1px solid #C6C6C6"
                    disabled={edited}
                    fontSize={15}
                  />
                </Box>

                <Box w={{ base: "250px", md: "300px" }} mb="5">
                  <FormLabel fontSize={14}>Last name</FormLabel>
                  <Input
                    type="text"
                    w="100%"
                    bg="#EEEEEE"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    border="1px solid #C6C6C6"
                    disabled={edited}
                    fontSize={15}
                  />
                </Box>

                <Box w={{ base: "250px", md: "300px" }} mb="5">
                  <FormLabel fontSize={14}>UserName</FormLabel>
                  <Input
                    type="text"
                    w="100%"
                    bg="#EEEEEE"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    border="1px solid #C6C6C6"
                    disabled={edited}
                    fontSize={15}
                  />
                </Box>
              </Box>
            </Flex>
          </FormControl>
        </Box>
        <Box mb="5">
          {edited ? (
            <Button
              bg="#FCFCFC"
              border="2px solid #EFEFEF"
              color="black"
              onClick={() => setEdited(false)}
              fontWeight="medium"
              fontSize={14}
            >
              Edit Profile
            </Button>
          ) : (
            <Button
              bg="#00BFB2"
              color="white"
              fontWeight="medium"
              fontSize={14}
              w="160px"
              onClick={() => FormHandler()}
            >
              Save changes
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ProfileForms;
