import api from "../axios";

const SignInApi = (data) => {
  return api.post("/auth/users/login", data);
};

const SignUpApi = (data) => {
  return api.post("/auth/users/signup", data);
};

const ConfirmAcountApi = (data) => {
  return api.get(`/auth/users/confirm/${data}`);
};

const RequestResetPasswordApi = (data) => {
  return api.post("/auth/users/resetPassword", data);
};

const ResetPasswordApi = (data) => {
  return api.post(`/auth/users/resetPassword/${data}`, data);
};

export {
  SignInApi,
  SignUpApi,
  RequestResetPasswordApi,
  ResetPasswordApi,
  ConfirmAcountApi,
};
