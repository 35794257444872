import ErrorHandler from "../../redux/axios/Utils/ErrorHandler";
import { GetApplicationsApi } from "../../redux/axios/apis/applications";
import { setApplications } from "../../redux/features/applications/applicationSlice";
import { dispatch } from "../../redux/store";
import { createResponse, setLoading } from "../../redux/utils/UtilSlice";

export const ApplicationsLoader = async () => {
    dispatch(setLoading(true));
  try {
    const res = await GetApplicationsApi();
    dispatch(setApplications(res.data.data));
    dispatch(setLoading(false));
    return res.data;
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(createResponse(ErrorHandler(error)));
  }
}