import { Flex, Text, Image } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useColors } from "../../util/colors";
import noTaliosImage from "../Talios/assets/undraw_no_data_re_kwbl.svg";
import ApplicationTable from "./components/AppTable";

const Applications = () => {
  const { user } = useSelector((state) => state.user);
  const { applications } = useSelector((state) => state.application);

  return (
    <Flex flexDirection="column" w="100%">
      <Flex justifyContent="space-between">
        <Text fontSize="23px" fontWeight={600} color={useColors.appSecondary}>
          Hello {user.firstName} 😊, here are your applications
        </Text>
      </Flex>

      <Flex justifyContent="center" alignItems="center" width="100%">
        {applications.length > 0 ? (
          <Flex flexWrap="wrap" mt="30px" gap="5" width="100%">
            <Flex width="100%" gap="14px" flexDirection="column">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontWeight="600" fontSize="20px" color="#081946">
                  Application List
                </Text>
              </Flex>
              <Flex
                flexDirection="column"
                gap="31px"
                p="15px 0px"
                borderRadius="5px"
                flexShrink="0"
                bgColor="#FFF"
                width="100%"
              >
                <ApplicationTable data={applications} />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
            direction="column"
            mt="80px"
            style={{ width: "60%", height: "25%" }}
          >
           
            <Image
              src={noTaliosImage}
              alt="No Talios"
              style={{ width: "50%", height: "100%" }}
            />
            <Text fontSize="25px" fontWeight="600" mt="20px">
              You don't have any application yet.
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Applications;
