import React, { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { handleToggleNavbar } from "../../Components/Navigation";
import Sidebar from "../../Components/Sidebar";
import { dispatch } from "../../redux/store";
import { GetApplications } from "../../redux/features/applications/service";

const Dashboard = () => {
  useEffect(() => {
    dispatch(GetApplications())
  }, [])
  return (
    <React.Fragment>
      <Box
        className="dashboard_navbar_side_overlay_bg"
        id="overlay"
        onClick={handleToggleNavbar}
      ></Box>
      <Sidebar />
      {/** DASHBOARD MAIN SECTION */}
      <Flex
        id="main-section"
        className="main"
        gap="30px"
        flexDirection="column"
      >
        <Outlet />
      </Flex>
    </React.Fragment>
  );
};

export default Dashboard;
