import { Flex, Text } from "@chakra-ui/react";
import { formatString } from "../helpers";
import { useColors } from "../../../util/colors";
import { Icon } from "@iconify/react";
import { dispatch } from "../../../redux/store";
import { createResponse, setLoading } from "../../../redux/utils/UtilSlice";
import { DeleteTalioApi } from "../../../redux/axios/apis/talios";
import { GetTalios } from "../../../redux/features/tailos/service";
import ErrorHandler from "../../../redux/axios/Utils/ErrorHandler";

export const ContentBox = ({ talio }) => {
  const stack = talio.stack;

  const DeleteHandler = async () => {
    dispatch(setLoading(true));
    try {
      const res = await DeleteTalioApi(talio._id);

      if (res) {
        dispatch(GetTalios());
        dispatch(setLoading(false));
        dispatch(
          createResponse({
            title: "Success",
            type: "success",
            message: "Talio deleted successfully",
          })
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(createResponse(ErrorHandler(error)));
    }
  };
  return (
    <Flex
      width="350px"
      flexDirection="column"
      h="250px"
      boxShadow="md"
      p="20px 50px"
      justifyContent="space-between"
    >
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Text fontSize="30px" fontWeight="600" color={useColors.appSecondary}>
          {formatString(talio.subCategory)}
        </Text>
      </Flex>
      <Flex gap="10px" flexDirection="column">
        <Flex
          fontSize="14px"
          fontWeight="600"
          height="50px"
          justifyContent="center"
          alignItems="center"
          borderRadius="25px"
          width="50%"
          bgColor={useColors.appPrimary}
          color={useColors.appSecondary}
        >
          {talio.skillLevel}
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="20px" fontWeight="600">
            {stack.join(", ")}
          </Text>
          <Flex justifyContent="space-between" alignItems="center" gap="1.5rem">
            {/* <Icon
            icon="iconamoon:edit-duotone"
            color="#093b3c"
            width="22"
            cursor="pointer"
            onClick={() => {
              dispatch(setCurrentTalio(talio));
              setModalOpen(true);
            }}
          /> */}
            <Icon
              icon="material-symbols:delete-outline"
              color="#093b3c"
              width="25"
              cursor="pointer"
              onClick={() => DeleteHandler()}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
