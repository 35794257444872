import { Box, Flex } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "../Components/Navigation";
import React from "react";
import { useEffect } from "react";
import Response from "../Components/Response";
import LoadingScreen from "../Components/LoadingScreen";
import { useSelector } from "react-redux";

export default function Rootlayout() {
  // ** This hook will auto scroll the pages to top on navigation to a new page...
 
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <Flex flexDirection="column">
      <Response />
      <LoadingScreen />
      <MainNavigation />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        w="100%"
        h="100%"
        overflowX="hidden"
      >
        { <Outlet />}
      </Box>
    </Flex>
  );
}
