import { useState } from "react";
import SettingsHeader from "./components/SettingsHeader";
import { Box } from "@chakra-ui/react";
import ProfileForms from "./subpages/ProfileForms";
import SecurityForm from "./subpages/SecurityForm";
import Pricing from "./subpages/Pricing";
import { useLoaderData } from "react-router-dom";

const Index = () => {
  const data = useLoaderData();
  const [navPosition, setNavPosition] = useState(0);

  return (
    <Box h="100%" pb="8">
      <Box width={{ base: "95%", md: "85%" }} mx="auto">
        <SettingsHeader
          navPosition={navPosition}
          setNavPosition={setNavPosition}
        />
        {navPosition === 0 && <ProfileForms />}
        {navPosition === 1 && <Pricing plan={data}/>}
        {navPosition === 2 && <SecurityForm />}
      </Box>
    </Box>
  );
};

export default Index;
