import {
  Box,
  Flex,
  UnorderedList,
  ListItem,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';
import { Link as ReachLink, useNavigate } from 'react-router-dom';
import { useColors } from '../../util/colors';
import React from 'react';
import Logo from '../../assets/svgs/Logosuite Mint.svg';

const WebFooter = () => {
  const navigate = useNavigate();
  return (
    <Box
      w='100%'
      bg={useColors.appSecondary}
      flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
      color='#fff'
      mt='50px'
      p={{ base: '10px', md: '20px', lg: '50px' }}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Flex width='40%'>
        <Flex
          width='250px'
          alignItems='flex-start'
          justifyContent='flex-start'
          onClick={() => navigate('/')}
        >
          <img src={Logo} alt='logo' />
        </Flex>
      </Flex>

      <Box
        width={{ base: '100%', md: '50%', lg: '25%' }}
        style={{ color: useColors.appPrimary }}
      >
        <UnorderedList
          listStyleType='none'
          fontSize='18px'
          display='flex'
          flexDirection='column'
          lineHeight='2'
        >
          <ListItem fontWeight='bold'>Menu</ListItem>
          <ListItem as={ReachLink} to='/#home'>
            Home
          </ListItem>
          <ListItem as={ReachLink} to='/#about'>
            About
          </ListItem>
          <ListItem as={ReachLink} to='/#education'>
            Insights
          </ListItem>
        </UnorderedList>
      </Box>
      <Box width={{ base: '100%', md: '50%', lg: '25%' }}>
        <UnorderedList
          listStyleType='none'
          fontSize='18px'
          display='flex'
          flexDirection='column'
          mt='40px'
          lineHeight='2'
          style={{ color: useColors.appPrimary }}
        >
          <ListItem fontWeight='bold'>We're on socials</ListItem>
          <ListItem as={ReachLink} to='https://instagram.com/usetaliot'>
            Instagram
          </ListItem>
          <ListItem as={ReachLink} to='https://twitter.com/usetaliot'>
            X (formerly Twitter)
          </ListItem>
          <ListItem as={ReachLink} to='https://linkedin.com/in/taliot'>
            LinkedIn
          </ListItem>
          <ListItem as={ReachLink} to='https://facebook.com/usetaliot'>
            Facebook
          </ListItem>
        </UnorderedList>
      </Box>
      <Box
        width={{ base: '100%', md: '50%', lg: '25%' }}
        style={{ color: useColors.appPrimary }}
      >
        <Text
          fontSize={{ base: '18px', md: '20px' }}
          mb='10px'
          pt={{ base: '20px', lg: '0px' }}
          textAlign={{ base: 'center', md: 'initial', lg: 'initial' }}
        >
          Subscribe to our newsletter!
        </Text>
        <Flex
          width={{ base: '100%' }}
          flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
          rowGap='2px'
        >
          <Input
            placeholder='Email address'
            width={{ base: '100%', md: '200px', lg: '300px' }}
            borderRadius='10px'
            outline='none'
            border='none'
            style={{
              background: useColors.appPrimary,
              color: useColors.appWhite,
            }}
          />
          <Button
            width={{ base: '40%', md: '40%', lg: '100px' }}
            fontSize={{ base: '15px', lg: '16px' }}
            alignSelf={{ base: 'center', lg: 'initial' }}
            ml='10px'
            mt={{ base: '10px', lg: '0px' }}
            borderRadius='7px'
            style={{ background: useColors.appGrey }}
          >
            Subscribe
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default WebFooter;
