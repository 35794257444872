import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: {
    message: null,
    type: "",
    title: "",
  },
  sidebar: true,
  loading: false
};

const UtilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    createResponse: (state, action) => {
      state.response = {
        message: action.payload.message,
        type: action.payload.type,
      };
    },
    setSideBarState: (state, action) => {
      state.sidebar = action.payload
    },
    setLoading: (state, action)=> {
      state.loading = action.payload
    }
  },
});

export const { createResponse, setSideBarState, setLoading } = UtilSlice.actions;

export default UtilSlice.reducer;
