import {
    Box,
    Input,
    FormControl,
    FormLabel,
    Button,
    Flex,
    useToast,
  } from "@chakra-ui/react";
  import { useState } from "react";
import { dispatch } from "../../../redux/store";
import { setUser } from "../../../redux/features/user/userSlice";
import { CreateSecurityApi } from "../../../redux/axios/apis/security";
  
  const SecurityForm = () => {  
    const toast = useToast()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')

    const [edited, setEdited] = useState(true);
  
    const formBody = {
     email, password
    };
  
    const FormHandler = async () => {
        try {
          const res = await CreateSecurityApi(formBody);
          if (res) {
            console.log(res.data);
            toast({
              title: res.data.message,
              status: "success",
              duration: 5000,
            });
            setEdited(true);
          }
        } catch (error) {
          console.log(error);
          toast({
            title: error.response.data.message,
            status: "error",
            duration: 5000,
          });
        }
      };
    return (
      <Box w="100%" h="auto" bg="white" p={{ base: 5, md: 7 }} borderRadius={5}>
        <Flex justifyContent="space-between" flexWrap="wrap-reverse">
          <Box w="630px">
            <FormControl>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Box>
  
                  <Box w={{ base: "250px", md: "300px" }} mb="5">
                    <FormLabel fontSize={14}>Email address</FormLabel>
                    <Input
                      type="email"
                      w="100%"
                      bg="#EEEEEE"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      border="1px solid #C6C6C6"
                      disabled={edited}
                      fontSize={15}
                    />
                  </Box>
  
                  <Box w={{ base: "250px", md: "300px" }} mb="5">
                  <FormLabel fontSize={14}>App Password</FormLabel>
                  <Input
                    type="password"
                    w="100%"
                    bg="#EEEEEE"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    border="1px solid #C6C6C6"
                    disabled={edited}
                    fontSize={15}
                  />
                </Box>
                </Box>
  
             
              </Flex>
            </FormControl>
          </Box>
          <Box mb="5">
            {edited ? (
              <Button
                bg="#FCFCFC"
                border="2px solid #EFEFEF"
                color="black"
                onClick={() => setEdited(false)}
                fontWeight="medium"
                fontSize={14}
              >
                Edit
              </Button>
            ) : (
              <Button
                bg="#00BFB2"
                color="white"
                fontWeight="medium"
                fontSize={14}
                w="160px"
                onClick={() => FormHandler()}
              >
                Save changes
              </Button>
            )}
          </Box>
        </Flex>
      </Box>
    );
  };
  
  export default SecurityForm;
  