import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 applications: null
};

const ApplicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplications: (state, action) => {
      state.applications = action.payload;
    },
  },
});

export const {setApplications} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
