import ErrorHandler from "../../axios/Utils/ErrorHandler";
import { GetApplicationsApi } from "../../axios/apis/applications";
import { dispatch } from "../../store";
import { createResponse, setLoading } from "../../utils/UtilSlice";
import { setApplications } from "./applicationSlice";

export const GetApplications = () => async () => {
    dispatch(setLoading(true));
  try {
    const res = await GetApplicationsApi();
    dispatch(setApplications(res.data.data));
    dispatch(setLoading(false));
    return res.data
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(createResponse(ErrorHandler(error)));
  }
}