import React from 'react';
import { Box, Text, Flex, Badge } from '@chakra-ui/react';
import { useColors } from '../../../util/colors';
import { useSelector } from 'react-redux';
import { useLocation, Outlet } from 'react-router-dom';
import { ContentBox } from './contentBox';

const Activity = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);
  const { talios } = useSelector((state) => state.talio);
  const { applications } = useSelector((state) => state.application);
  return (
    <Flex flexDirection='column' gap='20px' w='100%'>
      <Flex justifyContent='space-between' gap='20px' alignItems='center'>
        <Box
          display='flex'
          flexDirection={{ base: 'column', md: 'row' }}
          w={{ base: '100%', md: '100%' }}
          px='5px'
          fontSize={{ base: '19px', md: '23px' }}
          fontWeight={600}
        >
          Welcome to your cockpit,{' '}
          <Text ml='5px' fontWeight={600} color={useColors.appSecondary}>
            {user.firstName}
          </Text>
        </Box>
        <Badge colorScheme='green' p='1'>
          Active
        </Badge>
      </Flex>
      <Flex
        flexDirection='column'
        width={{ base: '100%', md: '85%' }}
        gap='20px'
      >
        <Flex
          justifyContent='space-between'
          flexDirection={{ base: 'column', md: 'row' }}
          gap='10px'
        >
          <ContentBox title='Talios' count={talios?.length} />
          <ContentBox title='Applications' count={applications?.length} />
        </Flex>
        <Flex justifyContent='space-between'>
          <Flex></Flex>
          <ContentBox title='Application success rate' count='100%' />
        </Flex>
      </Flex>
      <Box
        p='10px'
        w='100'
        borderRadius='5px'
        border='1px solid #eee'
        mt='20px'
        boxShadow='1px 1px 2px 0 rgba(42, 141, 0, 0.3)'
      >
        <Box w='100%' fontWeight={600} p='10px' fontSize='15px'>
          {!pathname.includes('history')
            ? 'My applications'
            : 'My applications history'}
        </Box>
        <Outlet />
      </Box>
    </Flex>
  );
};

export default Activity;
