import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useColors } from '../../../util/colors';

const HeadersData = ['S/N', 'Title', 'Company', 'Status', 'Date'];

const ApplicationTable = ({ data }) => {
  return (
    <TableContainer p='0px'>
      <Table variant='striped' colorScheme='whiteAlpha'>
        <Thead>
          <Tr>
            {HeadersData.map((ele, index) => {
              return (
                <Th key={index}>
                  <TableHeaders title={ele} />
                </Th>
              );
            })}
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.map((value, index) => {
            return (
              <TableRows
                title='Frontend Engineer'
                company='Solana Foundation'
                index={index + 1}
                date='15 Oct, 2023'
                status={value.status}
              />
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const TableHeaders = ({ title }) => {
  return (
    <Flex
      gap='7px'
      textAlign='center'
      justifyContent='center'
      alignItems='center'
    >
      <Text fontWeight='500' fontSize='12px' color='#000'>
        {title}
      </Text>
    </Flex>
  );
};

const TableRows = ({ index, title, company, status, date }) => {
  return (
    <Tr
      height='56px'
      borderRadius='2px'
      padding='20px'
      fontSize='14px'
      color='#030229'
      fontWeight='500'
    >
      <Td textAlign='center'>{index}</Td>
      <Td textAlign='center'>{title}</Td>
      <Td textAlign='center'>{company}</Td>
      <Td justifyContent='center' display='flex'>
        <Flex
          width='87px'
          height='32px'
          borderRadius='22.5px'
          flexShrink='0'
          fontSize='12px'
          fontWeight='500'
          bgColor={status === 'success' ? useColors.appPrimary : '#F5DCDC'}
          alignItems='center'
          justifyContent='center'
        >
          <Text
            textAlign='center'
            color={status === 'success' ? useColors.appSecondary : '#D14F4F'}
          >
            {status}
          </Text>
        </Flex>
      </Td>
      <Td textAlign='center'>{new Date().toDateString()}</Td>
    </Tr>
  );
};

export default ApplicationTable;
