import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Rootlayout from "./layouts/Rootlayout";
import Welcome from "./pages/Landing";
import ResetPassword, { action as resetAction } from "./pages/ResetPassword";
import ErrorPage from "./pages/ErrorPage";
import { checkToken as tokenLoader } from "./Auth/getToken";
import Error from "./pages/Error";
import Dashboard from "./pages/Dashboard";
import { loader as dashboardLoader } from "./util/dashboard";
import Recent from "./pages/Dashboard/components/recent";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import SignIn from "./pages/SignIn"
import Signup from "./pages/SignUp";
import Talios from "./pages/Talios";
import Activity from "./pages/Dashboard/components/activity";
import Settings from "./pages/Settings"
import { SettingsLoader } from "./pages/Settings/loader";
import Applications from "./pages/Applications";
import Wallet from "./pages/Wallet";
import { ApplicationsLoader } from "./pages/Applications/loader";
// This can be imported by anyone throughout the app instead of having to declare the color or site name in all files...
// For example, I imported it in the ErrorPage.js file
// And to change the color, we only have to change this constant value here...


const route = createBrowserRouter([
  {
    path: "/",
    element: <Rootlayout />,
    id: "root",
    loader: tokenLoader,
    errorElement: <Error />,
    children: [
      {
        index: true,
        path: "/",
        element: <Welcome />,
      },
      {
        path: "*",
        element: <ErrorPage />, //This is the page for all 404 routes on this website!
      },
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "signUp",
        element: <Signup />,
      },
      {
        path: "legal",
        children: [
          {
            index: true,
            path: "privacy-policy",
            element: <Privacy/>,
          },
          {
            path: "terms-of-use",
            element: <Terms/>,
          },
        ],
      },
      // {
      //   path: "Signup",
      //   element: <Signup />,
      //   action: SignupAction,
      // },
      {
        path: "resetpassword/:Id/:token/:tokenID",
        element: <ResetPassword />,
        action: resetAction,
      },
      {
        path: "dashboard/",
        element: <Dashboard />,
        loader: dashboardLoader,
        children: [
          {
            index: true,
            path: "",
            element: <Activity />,
          },
          {
            path: "history",
            element: <Recent />,
          },
          {
            path: "talios",
            element: <Talios />,
          },
          {
            path: "applications",
            element: <Applications />,
            loader: ApplicationsLoader
          },
          {
            path: "wallet",
            element: <Wallet />,
          },
          {
            path: "settings",
            loader: SettingsLoader,
            element: <Settings />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={route} />;
}

export default App;
