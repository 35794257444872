import { Box, Spinner } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {useColors} from "../../util/colors"

export default function LoadingScreen() {
  const { loading } = useSelector((state) => state.util);
  return (
    <>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.6)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={useColors.appPrimary}
            size="xl"
          />
        </Box>
      )}
    </>
  );
}
