import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import utilReducer from "./utils/UtilSlice";
import userReducer from "./features/user/userSlice"
import talioReducer from './features/tailos/talioSlice'
import applicationReducer from './features/applications/applicationSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    util: utilReducer,
    user: userReducer,
    talio: talioReducer,
    application: applicationReducer
   
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  devTools: true,
});

export const dispatch = store.dispatch;

export default store;
