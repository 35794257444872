import { Box, Text, Flex } from '@chakra-ui/react';
import React from 'react';
// import bannerImage from "../../assets/images/img1.png";
import { BsDot } from 'react-icons/bs';
import { useColors } from '../../util/colors';
import { useActiveNav } from '../../util/useActiveNav';
const WebBanner = () => {
  const homeRef = useActiveNav('Home');
  return (
    <Box
      ref={homeRef}
      id='homeContainer'
      w='100%'
      h={{ base: 'calc(100vh - 60px)', md: 'calc(100vh - 60px)', lg: '100vh' }}
      backgroundRepeat='no-repeat'
      style={{ backgroundPosition: 'center' }}
      backgroundSize='contain'
      backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${'https://res.cloudinary.com/taliot/image/upload/v1697199168/assets/landing_page/rbcmrygjs9edq5qbnh9p.png'})`}
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexFlow='column'
      color='#fff'
    >
      <Box
        flexWrap='wrap'
        px='5px'
        fontSize={{ base: '22px', md: '55px', lg: '75px' }}
        fontWeight={700}
        textShadow='0 2px 6px rgba(255, 255, 255, 0.6)'
        textTransform='uppercase'
        display='flex'
        alignItems='center'
        justifyContent='center'
        mb='20px'
      >
        ELEVATE <BsDot />{' '}
        <span style={{ color: useColors.appYellow }}>MATCH</span> <BsDot />{' '}
        AUTOMATE
      </Box>
      <Flex
        flexWrap='wrap'
        px='20px'
        maxW='600px'
        fontSize={{ base: '13px', md: '14px', lg: '18px' }}
        textAlign='center'
        gap='15px'
        flexDirection='column'
      >
        <Text fontSize='22px'>
          {' '}
          Welcome to Taliot - Your Path to Career Excellence
        </Text>
        <Text>
          At Taliot, we believe that your career journey should be nothing short
          of extraordinary. We understand the challenges that job seekers face,
          and we're here to make your path to success clearer, faster, and more
          exciting than ever.
        </Text>
      </Flex>
    </Box>
  );
};

export default WebBanner;
