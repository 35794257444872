import { redirect } from "react-router-dom";
import { GetUserProfileApi } from "../redux/axios/apis/profile";
import { dispatch } from "../redux/store";
import { setUser } from "../redux/features/user/userSlice";
import { createResponse } from "../redux/utils/UtilSlice";
import ErrorHandler from "../redux/axios/Utils/ErrorHandler";
import { GetTalios } from "../redux/features/tailos/service";

export async function loader() {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return redirect("/login");
  }
  try {
    const response = await GetUserProfileApi();

    if (response.data) {
      dispatch(setUser(response.data.data))
      sessionStorage.setItem('user', JSON.stringify(response.data.data))
      dispatch(GetTalios())
      return response.data.data;
    }
  } catch (error) {
    dispatch(createResponse(ErrorHandler(error)))
    sessionStorage.clear()
    return redirect("/login");
  }
}
