import { Box, Flex } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useColors } from '../../util/colors';
import React, { useRef, useEffect } from 'react';
import { useAppContext } from '../../context';
import { FaTimes } from 'react-icons/fa';
import Logo from '../../assets/svgs/HorizontalLogo.svg';
import { dispatch } from '../../redux/store';
import { setSideBarState } from '../../redux/utils/UtilSlice';
import { useSelector } from 'react-redux';

export const handleToggleNavbar = () => {
  document.getElementById('collapse-sidebar').classList.toggle('collapse');
  document.getElementById('header').classList.toggle('collapse');
  document.getElementById('main-section').classList.toggle('collapse');
  document.getElementById('overlay').classList.toggle('collapse');
};
export const handleCloseNavbar = () => {
  document.getElementById('collapse-sidebar').classList.remove('collapse');
  document.getElementById('header').classList.remove('collapse');
  document.getElementById('main-section').classList.remove('collapse');
  document.getElementById('overlay').classList.remove('collapse');
};

function MainNavigation() {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const { sidebar } = useSelector((state) => state.util);

  // ** GET CURRENT ROUTE FROM REACT ROUTER DOM
  const { pathname } = useLocation();
  // ** GET APP CONTEXT STORE
  const { activeNavLinkId, setActiveNavLinkId } = useAppContext();

  const navRef = useRef();
  const toggleNavbar = () => {
    document.body.classList.toggle('body-sticky-on-header-open');
    navRef.current.classList.toggle('responsive');
  };

  // ** RESET THE ACTIVENAVLINK TO NULL IF URL DOES NOT INCLUDE # USED IN THE SINGLE PAGES ROUTING
  useEffect(() => {
    if (!pathname.includes('#')) setActiveNavLinkId('');
  }, [pathname, setActiveNavLinkId]);

  // ** ROUTING LINKS
  const navLinks = [
    /** IF YOU MAKE CHANGES TO ANY OF THE 'navLinkId' OR 'scrollToId', DO ENSURE YOU REPLICATE THE CHANGES
     * IN THE RESPECTIVE COMPONENTS FILES RENDERING THOSE SECTIONS
     **/
    {
      navLinkId: 'Home',
      icon: '',
      scrollToId: 'homeContainer',
      link: '/#home',
    },
    {
      navLinkId: 'About Us',
      icon: '',
      scrollToId: 'aboutContainer',
      link: '/#about-us',
    },
    {
      navLinkId: 'Insights',
      icon: '',
      scrollToId: 'educationContainer',
      link: '/#education',
    },
    {
      navLinkId: 'Pricing',
      icon: '',
      scrollToId: 'pricingContainer',
      link: '/#pricing',
    },
  ];

  const logOut = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  return (
    <Box
      id='header'
      bgColor={useColors.appWhite}
      className={`${
        pathname.includes('/dashboard') && 'dashboard-header'
      } header`}
    >
      {/** WEBSITE LOGO SECTION */}
      {!pathname.includes('/dashboard') && (
        <Flex w='175px' h='85px' onClick={() => navigate('/')}>
          <img src={Logo} alt='logo' />
        </Flex>
      )}
      {pathname.includes('/dashboard') && (
        <Box
          onClick={() => {
            dispatch(setSideBarState(!sidebar));
            handleToggleNavbar();
          }}
          className='toggle-open-sidepanel'
        >
          <Box w='30px'></Box>
          <Box w='30px'></Box>
          <Box w='30px'></Box>
        </Box>
      )}
      {/** MOBILE MENU BUTTON/SWITCH */}
      {!pathname.includes('/dashboard') && (
        <Box onClick={toggleNavbar} className='toggle-open'>
          <Box w='30px'></Box>
          <Box w='25px'></Box>
          <Box w='30px'></Box>
        </Box>
      )}
      {/** WEB NAVIGATION SECTION */}
      <Box
        className='nav-wrapper'
        w={!pathname.includes('/dashboard') && '80%'}
        ref={navRef}
      >
        {!pathname.includes('/dashboard') && !token && (
          <Box className='navigations' w='70%'>
            <Box className='btn-close' onClick={toggleNavbar}>
              <FaTimes />
            </Box>
            <Box className='responsive-nav-logo'>
              <img src={Logo} alt='logo' />
            </Box>
            {navLinks.map(({ navLinkId, scrollToId, link, icon }, i) => (
              <React.Fragment key={i}>
                <NavLink
                  navLinkId={navLinkId}
                  icon={icon}
                  scrollToId={scrollToId}
                  link={link}
                  activeNavLinkId={activeNavLinkId}
                  setActiveNavLinkId={setActiveNavLinkId}
                  toggleNavbar={toggleNavbar}
                />
                {i !== 3 && <span className='divider'>|</span>}
              </React.Fragment>
            ))}
          </Box>
        )}
        {/** AUTH BUTTONS SECTION */}
        <Box className='auth-block'>
          {token && (
            <Flex color={useColors.appSecondary}>
              <Link onClick={logOut}>Sign Out</Link>
            </Flex>
          )}
          {!token && pathname !== '/login' && (
            <Flex>
              <Link onClick={toggleNavbar} to='/signup'>
                <Box
                  fontSize={15}
                  ml='4'
                  fontWeight={600}
                  color='#fff'
                  background={useColors.appSecondary}
                  px='25px'
                  py='10px'
                  borderRadius='5%'
                  transition='all 0.3s ease'
                  _hover={{ opacity: 0.6 }}
                >
                  Get Started
                </Box>
              </Link>

              <Link onClick={toggleNavbar} to='/login'>
                <Box
                  fontSize={15}
                  ml='4'
                  fontWeight={600}
                  color='#fff'
                  background={useColors.appSecondary}
                  px='25px'
                  py='10px'
                  borderRadius='5px'
                  transition='all 0.3s ease'
                  _hover={{ opacity: 0.6 }}
                >
                  Login
                </Box>
              </Link>
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
}

// ** CUSTOM NAVLINK TO RENDER ROUTING TO SPECIFIC PAGE SECTION ON SINGLE PAGE
const NavLink = ({
  navLinkId,
  icon,
  scrollToId,
  link,
  activeNavLinkId,
  setActiveNavLinkId,
  toggleNavbar,
}) => {
  const handleClick = () => {
    toggleNavbar();
    setActiveNavLinkId(navLinkId);
    document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Link
      to={link}
      id={navLinkId}
      style={{
        color:
          activeNavLinkId === navLinkId
            ? useColors.appSecondary
            : useColors.appSecondary,
        borderBottom:
          activeNavLinkId === navLinkId ? `2px solid ${useColors.appGrey}` : '',
      }}
      className='links'
      onClick={handleClick}
    >
      {icon}
      {navLinkId}
    </Link>
  );
};

export default MainNavigation;
