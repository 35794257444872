import api from "../axios";

const UpdateProfileApi = (data) => {
  return api.patch(`/profile/update/`, data);
};

const GetUserProfileApi = (id) => {
  return api.get(`/profile/`);
};

export { UpdateProfileApi, GetUserProfileApi };
