import { Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

const SkeletonLoader = () => {
  return (
    <Stack width="100%" gap="30px" mt="30px">
      <Skeleton height="60px" width="100%" />
      <Skeleton height="45px" width="75%" />
      <Skeleton height="30px" width="50%" />
    </Stack>
  );
};

export default SkeletonLoader;
