import {
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Image,
  Link,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useColors } from '../../util/colors';
import { SignUpApi } from '../../redux/axios/apis/auth';
import { dispatch } from '../../redux/store';
import { createResponse } from '../../redux/utils/UtilSlice';
import ErrorHandler from '../../redux/axios/Utils/ErrorHandler';
import { useNavigate } from 'react-router-dom';
export default function Signup() {
  const navigate = useNavigate();
  const [show, setValue] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const togglePassword = (event) => setValue(!show);

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Is not a valid email').required('Required'),
    password: Yup.string()
      .required('Required')
      .matches(/^.{9,}$/i, 'Must be more than 8 charaters'),
    confirmPassword: Yup.string().required('Required'),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    username: '',
    referralId: '',
    referredBy: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await SignUpApi(values);
      if (res) {
        setIsSubmitting(false);
        navigate('/login');
      }
    } catch (error) {
      setIsSubmitting(false);
      dispatch(createResponse(ErrorHandler(error)));
    }
  };

  return (
    <Flex
      width='100%'
      justify='between'
      mt="20px"
      direction={{
        sm: 'column',
        base: 'row',
        md: 'column',
        lg: 'row',
        xl: 'row',
      }}
      height='100vh'
      color={useColors.appSecondary}
      style={{ overflow: 'hidden' }}
    >
      <Flex w='50%' display={{base:"none", md:"flex"}}>
        <Image
          src='https://res.cloudinary.com/taliot/image/upload/v1697199101/assets/landing_page/myxbxonzrtxaycsz11ix.png'
          w='100%'
        />
      </Flex>
      <Flex
        p='20px 40px'
        width={{ base:"100%", sm: '50%', lg: '35%' }}
        margin=' 0px auto'
        flexDirection='column'
      >
        <Heading size='lg'>Let's get your cockpit setup!</Heading>

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            console.log('hey');
            handleSubmit(values);
          }}
        >
          {({ values, handleChange, errors }) => (
            <Form className='w-full'>
              <Flex flexDirection='column' gap='15px'>
                <FormControl>
                  <FormLabel>First name</FormLabel>
                  <Input
                    type='text'
                    name='firstName'
                    placeholder='Enter your first name'
                    onChange={(e) => handleChange(e)}
                  />
                  {errors.firstName && (
                    <Text color='red'>{errors.firstName}</Text>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Last name</FormLabel>
                  <Input
                    type='text'
                    name='lastName'
                    placeholder='Enter your last name'
                    onChange={(e) => handleChange(e)}
                  />
                  {errors.lastName && (
                    <Text color='red'>{errors.lastName}</Text>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type='email'
                    name='email'
                    placeholder='Enter your email'
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {errors.email && <Text color='red'>{errors.email}</Text>}
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      pr='4.5rem'
                      type={show ? 'text' : 'password'}
                      placeholder='Enter password'
                      name='password'
                      onChange={(e) => handleChange(e)}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button
                        h='1.75rem'
                        size='sm'
                        onClick={togglePassword}
                        bgColor={useColors.appGrey}
                      >
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  {errors.password && (
                    <Text color='red'>{errors.password}</Text>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Password confirmation</FormLabel>
                  <Input
                    pr='4.5rem'
                    type={show ? 'text' : 'password'}
                    placeholder='Confirm your password'
                    name='confirmPassword'
                    onChange={(e) => handleChange(e)}
                  />

                  {(errors.password ||
                    values.password !== values.confirmPassword) && (
                    <Text color='red'>
                      {errors.password
                        ? errors.password
                        : "Password don't match"}
                    </Text>
                  )}
                </FormControl>
                <Button
                  color='white'
                  width='100%'
                  fontWeight='bold'
                  marginY='8px'
                  type='submit'
                  bgColor={useColors.appSecondary}
                >
                  {isSubmiting ? 'Loading...' : 'Get started'}
                </Button>
              </Flex>

              <Text mt='10px' mb='30px' textAlign='center'>
                Already have an account?{' '}
                <Link
                  href='/login'
                  marginLeft='16px'
                  color={useColors.appBlack}
                  fontWeight='bold'
                >
                  Login
                </Link>
              </Text>
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
}
